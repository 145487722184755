import { loadStripe } from "@stripe/stripe-js";

let stripePromise;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_live_y4GTQwXNLisExPxRj7psDpim");
  }
  return stripePromise;
};

export default getStripe;
