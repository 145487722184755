import React, { useEffect, useState } from "react";

import useResize from "./Resize";

import styles from "./Video.module.css";

import ReactPlayer from "react-player";

import axios from "axios";

import { useParams } from "react-router-dom";

const Video = () => {
  const [video, setVideo] = useState();
  const { id } = useParams();
  let size = useResize();

  const baseURL =
    "https://7c962zzt65.execute-api.us-east-1.amazonaws.com/default/singleVideo";

  const getVideo = async () => {
    let response = await axios.post(baseURL, {
      VideoId: id,
    });
    console.log(response);
    let data = response.data.Items[0];

    setVideo(data);
    console.log(id, typeof id);
  };

  useEffect(() => {
    getVideo();
  }, []);
  return (
    <div className={styles.videoHolder}>
      {video && (
        <ReactPlayer
          width={size[0] < 600 ? `${size[0]}px` : "66%"}
          controls
          url={video.url}
        />
      )}
    </div>
  );
};

export default Video;
