import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

import styles from "./MobileMain.module.css";
import axios from "axios";

const baseURL =
  "https://7c962zzt65.execute-api.us-east-1.amazonaws.com/default/";

const MobileMain = () => {
  const navigate = useNavigate();
  const [videos, setVideos] = useState();

  useEffect(() => {
    getVideos();
  }, []);

  const getVideos = async () => {
    let response = await axios.get(baseURL);
    setVideos(response.data.Items);
  };

  const navigateToVideo = (id) => {
    navigate(`/video/${id}`);
  };

  return (
    <div>
      <h1 className={styles.headerH1}>FistBro.com</h1>

      <div className={styles.imagesHolder}>
        {videos &&
          videos.map((video, index) => {
            return (
              <div
                className={styles.imageHolder}
                key={index}
                onClick={() => navigateToVideo(video.VideoId)}
              >
                <div className={styles.imageLabel}>{video.name}</div>
                <img
                  className={styles.img}
                  key={index}
                  loading={"lazy"}
                  src={`${video.poster}`}
                ></img>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MobileMain;
