import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./Landing.module.css";

import useResize from "./Resize";

const Landing = () => {
  const navigate = useNavigate();
  useEffect(() => {
    let hasConsented = sessionStorage.getItem("hasConsented");

    if (hasConsented === "true") {
      navigate("/main");
    }
  }, []);

  let size = useResize();
  const click = () => {
    sessionStorage.setItem("hasConsented", "true");
    navigate("/main");
  };

  const hasConsented = () => {
    let token = sessionStorage.getItem("hasConsented");
    return token === "true" ? true : false;
  };

  return (
    <>
      {!hasConsented() && (
        <div
          className={styles.wrapper}
          style={{ width: `${size[0]}px`, height: `${size[1]}px` }}
        >
          <div className={styles.modal}>
            <p className={styles.p}>
              I certify that I am 18 years of age or older, and am lawfully able
              to view adult content in my region.
            </p>
            <button onClick={() => click()} className={styles.link} to="/main">
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Landing;
