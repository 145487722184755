import React, { useState, useEffect } from "react";

function useResize() {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const handleResize = () => {
    console.log("resizing", window.innerWidth, window.innerHeight);
    setWindowSize([window.innerWidth, window.innerHeight]);
    console.log("ref", windowSize);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return windowSize ? windowSize : [0, 0];
}

export default useResize;
