import "./App.css";
import Main from "./components/Main";
import Landing from "./components/Landing";
import CRUD from "./components/CRUD";
import Video from "./components/Video";
import Success from "./components/Success";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Landing />}></Route>
        <Route exact path="/main" element={<Main />}></Route>
        <Route exact path="/CRUD" element={<CRUD />}></Route>
        <Route exact path="/video/:id" element={<Video />}></Route>
        <Route exact path="/success/:id" element={<Video />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
