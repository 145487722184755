import React, { useState } from "react";
import AddressModal from "./AddressModal";
import getStripe from "./getStripe";

import axios from "axios";
import { useNavigate } from "react-router-dom";

const CRUD = () => {
  const [VideoId, setVideoId] = useState();
  const [name, setName] = useState();
  const [url, setUrl] = useState();
  const [poster, setPoster] = useState();
  const [address, setAddress] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const baseURL =
    "https://7c962zzt65.execute-api.us-east-1.amazonaws.com/default/";

  const clickPost = async () => {
    let obj = {
      ...(VideoId && { VideoId: VideoId }),
      ...(name && { name: name }),
      ...(url && { url: url }),
      ...(poster && { poster: poster }),
    };

    let response = await axios.post(baseURL, obj);
    console.log(obj);
    console.log(response);
  };

  const clickDelete = async () => {
    let response = await axios.delete(baseURL, {
      data: { VideoId: VideoId },
    });
    console.log(VideoId);
    console.log(response);
  };

  const clickGet = async () => {
    let response = await axios.get(baseURL);
    console.log(response);
  };

  const clickGetSingleVideo = async () => {
    const getSingleVideoURL =
      "https://7c962zzt65.execute-api.us-east-1.amazonaws.com/default/singleVideo";

    let response = await axios.post(getSingleVideoURL, {
      VideoId: VideoId,
    });
    console.log(response);
  };

  const openModal = () => {
    setModalOpen(!modalOpen);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const checkout = async () => {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: "price_1NRcrmGXeMoAmTUHhcj4kSrK",
          quantity: 1,
        },
      ],
      mode: "payment",
      successUrl: "https://fistbro.com/success/1",
      cancelUrl: "https://fistbro.com/success/2",
    });
    console.warn(error.message);
  };

  return (
    <div
      style={{
        position: "relative",
        zIndex: 2,
        height: "100vh",
        width: "100vw",
      }}
    >
      <input
        placeholder="VideoId"
        onChange={(event) => setVideoId(event.target.value)}
      ></input>
      <input
        placeholder="name"
        onChange={(event) => setName(event.target.value)}
      ></input>
      <input
        placeholder="url"
        onChange={(event) => setUrl(event.target.value)}
      ></input>
      <input
        placeholder="poster"
        onChange={(event) => setPoster(event.target.value)}
      ></input>

      <button onClick={() => clickPost()}>post</button>
      <button onClick={() => clickGet()}>get</button>
      <button onClick={() => clickGetSingleVideo()}>get single</button>
      <button onClick={() => clickDelete()}>delete</button>
      <button onClick={() => navigate("/video/nsfw")}>video</button>
      <button onClick={() => console.log(address)}>log state</button>
      <button onClick={() => checkout()}>checkout</button>

      <button
        style={{
          backgroundColor: "green",
        }}
        onClick={() => openModal()}
      >
        openModal
      </button>

      <AddressModal
        modalOpen={modalOpen}
        closeModal={closeModal}
        setAddress={setAddress}
      />
    </div>
  );
};

export default CRUD;
