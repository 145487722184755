import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useResize from "./Resize";

import MobileMain from "./MobileMain/MobileMain";
import DesktopMain from "./DesktopMain/DesktopMain";

const Main = () => {
  let size = useResize();
  const navigate = useNavigate();

  useEffect(() => {
    let hasConsented = sessionStorage.getItem("hasConsented");

    if (hasConsented !== "true") {
      navigate("/");
    }
  }, []);

  const hasConsented = () => {
    let token = sessionStorage.getItem("hasConsented");
    return token === "true" ? true : false;
  };

  const landing = () => {
    sessionStorage.setItem("hasConsented", "false");
    navigate("/main");
  };

  return (
    <>{hasConsented() && size[0] < 600 ? <MobileMain /> : <DesktopMain />}</>
  );
};

export default Main;
